<template>
    <Dialog v-if="show" :options="{ title: 'Update Organization Details', position: 'top' }" v-model="show">
      <template #body-content>
        <div class="flex flex-col gap-4">
          <FormControl
            v-model="organizationName"
            label="Organization Name"
            placeholder="Enter organization name"
            type="text"
            variant="outline"
            class="w-full"
          />
          <div v-if="logo">
            <img :src="logo" alt="Organization Logo" class="max-w-xs max-h-32 mb-2" />
            <Button @click="clearLogo" label="Remove Logo" variant="secondary" class="mb-2" />
          </div>
          <FileUploader
            v-else
            class="ml-auto"
            fileTypes="image/*"
            :upload-args="uploadArgs"
            @success="onLogoUploadSuccess"
            @failure="onLogoUploadError"
          >
            <template #default="{ openFileSelector, uploading }">
              <Button
                :loading="uploading"
                @click="openFileSelector"
                label="Upload Logo"
              />
            </template>
          </FileUploader>
          <ErrorMessage class="-mt-2 w-full" :message="error" />
        </div>
      </template>
      <template #actions>
        <Button variant="solid" class="w-full" @click="submit">
          Update Details
        </Button>
      </template>
    </Dialog>
  </template>
  
  <script>
  import { ref, computed } from 'vue';
  import { Button, Dialog, ErrorMessage, FormControl } from 'frappe-ui';
  import { getCachedDocumentResource } from 'frappe-ui';
  import { toast } from 'vue-sonner';
  import FileUploader from '@/components/FileUploader.vue';
  
  export default {
    name: 'OrganizationDetailsDialog',
    props: {
      siteName: { type: String, required: true }
    },
    components: {
      FileUploader,
      Dialog,
      FormControl,
      ErrorMessage,
      Button
    },
    setup(props) {
      const site = getCachedDocumentResource('Site', props.siteName);
      const show = ref(true);
      const error = ref('');
      const logo = ref('');
      const organizationName = ref('');
  
      const uploadArgs = computed(() => ({
        doctype: 'Site',
        docname: props.siteName,
        method: 'faceify_press.custom_script.site.site.add_site_logo'
      }));
  
      site.reload().then(() => {
        organizationName.value = site.doc?.custom_organization_name || '';
        logo.value = site.doc?.custom_logo || '';
      });
  
      const validate = () => {
        if (!organizationName.value) {
          error.value = 'Please enter the organization name.';
          return false;
        }
        return true;
      };
  
      const submit = () => {
        if (!validate()) return;
        
        site.setValue.submit(
          {
            custom_organization_name: organizationName.value,
            custom_logo: logo.value
          },
          {
            onSuccess: () => {
              show.value = false;
              toast.success('Organization details updated successfully');
            },
            onError: (error) => {
              console.error('Error setting organization details:', error);
              toast.error('Failed to update organization details');
            }
          }
        );
      };
  
      const onLogoUploadSuccess = (file_url) => {
        logo.value = file_url;
      };
  
      const onLogoUploadError = (errorMessage) => {
        toast.error(errorMessage);
      };
  
      const clearLogo = () => {
        site.setValue.submit(
          { custom_logo: '' },
          {
            onSuccess: () => {
              logo.value = '';
              toast.success('Logo removed successfully');
            },
            onError: (error) => {
              console.error('Error removing logo:', error);
              toast.error('Failed to remove logo');
            }
          }
        );
      };
  
      return {
        show,
        error,
        logo,
        organizationName,
        uploadArgs,
        submit,
        onLogoUploadSuccess,
        onLogoUploadError,
        clearLogo
      };
    }
  };
  </script>